<template>
  <div class="d-flex align-items-center flex-column">
    <BImg src="/logo-full.svg" width="100%" class="image" />
    <h1 class="title text-center">
      {{ $t('Global.LongName') }}
    </h1>
    <h2 class="sub-title">
      {{ $t('Auth.Login.Welcome', { name }) }}
    </h2>
  </div>
</template>

<script>
import { BImg } from 'bootstrap-vue'

export default {
  components: {
    BImg,
  },

  computed: {
    name() {
      const user = JSON.parse(localStorage.getItem('user'))
      if (!user) return ''
      return `${user.firstname} ${user.lastname}`
    },
  },
}
</script>

<style lang="scss" scoped>
.image {
  width: 20%;
  max-height: 250px;
  object-fit: contain;
  margin-bottom: 60px;
}
.title {
  color: var(--primary);
  font-weight: bold;
  opacity: 0.75;
  font-size: 2.5vw;
  margin-bottom: 80px;
}
.sub-title {
  font-size: 2vw;
  opacity: 0.49;
  color: #000000;
  font-weight: 600;
}
</style>
